






















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserService from "@/services/user.service";
import BaseVue from "@/core/BaseVue";
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
import User from "@/models/security/user.model";
import VueRecaptcha from "vue-recaptcha";
@Component({
  components: { VueRecaptcha },
})
export default class ForgotPassword extends BaseVue {
  public show: boolean = false;
  public isLoading: boolean = false;
  public email: string = "";
  loading = false;
  public isRecaptchatVeriFySuccefully = false;

  created() {
    this.show = true;
  }

  recovery() {
    if (this.email) {
         if(this.isRecaptchatVeriFySuccefully){
        this.$router.push(`forgotPassword/${this.email}/waysTorecoveryPassword`);
         }else{
            this.$buefy.toast.open({
             message: 'We need to verify that your are not a robot',
             type: 'is-warning',
             duration: 4000,
             position: "is-top-right",
         });
         }
    }else{
        this.$buefy.toast.open({
            message: 'Please enter your email',
            type: 'is-warning',
            duration: 4000,
            position: "is-top-right",
        });
    }
  }
  isSuccefully(){
    this.isRecaptchatVeriFySuccefully = true;
  }
  signIn(){
    this.$router.push("/home");
  }
}
